import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, QRCodeModule],
  exports: [ModalComponent],
})
export class ModalComponentModule {
  static getComponent() {
    return ModalComponent;
  }
}
