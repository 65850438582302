import { Injectable } from '@angular/core';
import { NavigationComponent } from './navigation.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationComponentInstance: NavigationComponent | null = null;
  private instanceSubject: BehaviorSubject<NavigationComponent | null> = new BehaviorSubject<NavigationComponent | null>(null);
  private isModalOpened: boolean = false;

  setNavigationComponentInstance(instance: NavigationComponent) {
    this.navigationComponentInstance = instance;
    this.instanceSubject.next(instance);
  }

  get instanceObservable(): Observable<NavigationComponent | null> {
    return this.instanceSubject.asObservable();
  }

  openDownloadAppModal() {
    if (this.navigationComponentInstance && !this.isModalOpened) {
      this.navigationComponentInstance.openDownloadAppModal();
      this.isModalOpened = true;
    } else {
      console.error('NavigationComponent instance is not set.');
    }
  }
}
