import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ClickhouseService } from '../../../shared/services/clickhouse.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() title: any;

  @Input() subtitle: any;

  @Input() customTitleClass: any;

  @Input() customSubtitleClass: any;

  @Input() customContentClass: any;

  @Input() eventId: any;

  @Input() token: any;

  @Input() content: any;

  @Input() additionalQRCode: any;

  @Input() buttons: any;

  @Input() onClose!: Observable<any>;

  @Input() canClose: any = true;

  @Input() modalInitCallback!: Function;

  constructor(public activeModal: NgbActiveModal, private clickHouse: ClickhouseService) {}

  ngOnInit(): void {
    this.modalInitCallback();
  }

  buttonCallback(callBack: Observable<any>) {
    callBack.subscribe(() => {
      this.activeModal.close();
    });
  }

  trackByFn(_index: any, item: any) {
    return item.id;
  }

  closeModal() {
    const callBack = () => {
      return this.onClose;
    };
    callBack().subscribe(() => {
      this.activeModal.close();
    });
  }

  downloadApp() {
    this.clickHouse.storeEvent('modal_install_app_click').subscribe(() => {});
    window.location.href = this.additionalQRCode;
  }
}
