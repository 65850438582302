export enum QRCodeErrorCorrectionLevel {
  low = 'low',
  medium = 'medium',
  quartile = 'quartile',
  high = 'high',
  L = 'L',
  M = 'M',
  Q = 'Q',
  H = 'H',
}

export enum QRCodeElementType {
  url = 'url',
  img = 'img',
  canvas = 'canvas',
  svg = 'svg',
}

export type QRCodeVersion =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40;
