import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { RouterModule } from '@angular/router';
import { NavigationService } from './navigation.service';

@NgModule({
  declarations: [NavigationComponent],
  imports: [CommonModule, RouterModule],
  exports: [NavigationComponent],
})
export class NavigationComponentModule {
  constructor(private navigationService: NavigationService) {}

  openDownloadModal() {
    this.navigationService.openDownloadAppModal();
  }
}
