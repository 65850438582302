<div class="w-100 pt-10 position-relative pb-7">
  <button
    *ngIf="canClose"
    type="button"
    class="modal-close btn-close"
    (click)="closeModal()"
  >
  </button>
  <div class="row justify-content-center">
    <div class="col-11">
      <p
        class="text-center mb-3"
        [ngClass]="customSubtitleClass"
        *ngIf="subtitle"
      >
        {{subtitle}}
      </p>
      <h2
        class="text-center mb-3"
        *ngIf="title"
        [innerHTML]="title"
        [ngClass]="customTitleClass"
      >
      </h2>
      <p
        class="text-center"
        *ngIf="content"
        [innerHTML]="content"
        [ngClass]="customContentClass"
      >
      </p>
      <div
        *ngIf="additionalQRCode"
        class="d-flex align-items-center justify-content-center"
      >
        <button
          type="button"
          class="download-app-modal_button app-btn-yellow btn btn-outline border-2 w-100 mt-3"
          (click)="downloadApp()"
        >
          Install
        </button>
      </div>
      <div
        *ngIf="additionalQRCode"
        class="qr-code-wrapper d-flex flex-column align-items-center justify-content-center position-relative"
      >
        <qrcode
          [qrdata]="additionalQRCode"
          [width]="120"
          class="d-flex align-items-center justify-content-center"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <div class="qr-code-wrapper_arrow-icon position-absolute">
          <svg xmlns="http://www.w3.org/2000/svg" width="87" height="63" viewBox="0 0 87 63" fill="none">
            <path d="M73.7472 60.9903C59.6345 41.4422 38.2624 23.4952 14.8494 14.0792" stroke="#FFEB3A" stroke-width="4" stroke-linecap="round"/>
            <path d="M20.7605 28.0684C18.6243 20.2732 14.3501 13.8783 8.75456 11.6279" stroke="#FFEB3A" stroke-width="4" stroke-linecap="round"/>
            <path d="M28.6924 8.03428C21.8183 12.2059 14.3501 13.8783 8.75456 11.6279" stroke="#FFEB3A" stroke-width="4" stroke-linecap="round"/>
          </svg>
        </div>
        <span class="qr-code-info">
          Scan QR code to install the app
        </span>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-center mt-5"
    *ngIf="buttons">
    <div class="col-11 col-md-9">
      <button
        class="btn w-100 mb-3"
        *ngFor="let button of buttons; trackBy: trackByFn"
        [class.btn-outline]="button.outline"
        [class.app-btn-yellow]="button.type === 'yellow'"
        [class.app-btn-black]="button.type === 'black'"
        [class.app-btn-gray]="button.type === 'gray'"
        (click)="buttonCallback(button.callback)"
      >
        {{ button.title }}
      </button>
    </div>
  </div>
</div>
